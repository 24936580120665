import {Card} from "react-bootstrap";

export function FormCard(props) {
    const {title, id, className, headerClassName, style, children} = props;
    return(
        <Card id={id} className={className} style={style}>
            <Card.Header className={`card-header-standard ${headerClassName}`}>
                <h1 style={{margin: "0"}}>{title}</h1>
            </Card.Header>
            <Card.Body>
                {children}
            </Card.Body>
        </Card>
    )
}