import {useAuthContext} from "../contexts/authContext";
import {Button} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import "./AdminOnlyColors.css";

export function AdminOnly(props){
    const {user, currentPresenter, loading} = useAuthContext();
    const navigate = useNavigate();
    if(user && currentPresenter.role === "admin"){return <>{props.children}</>}
    else if (!loading) {
        return(
        <>
            <h1 className={"unauthorized-error"} style={{textAlign:"center",marginTop:"5rem",fontSize:"4rem"}}>You are not authorized to access this page!</h1>
            <div style={{textAlign:"center"}}><Button onClick={()=>navigate('/dashboard')} style={{textAlign:"center",marginTop:"15%",fontSize:"3rem",borderRadius:"0.5em"}} className={"btn button-standard"}>Return to DASHBOARD</Button></div>
        </>)
    }
}
