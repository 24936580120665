import {FormCard} from "./FormCard";
import {Alert, Form, Spinner} from "react-bootstrap";
import {FormSubmitButton} from "./FormSubmitButton";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import {dbApi, fbAuth} from "../services/firebase";
import "./RegisterColors.css";

function handleError(error, setError, setOtherError) {
    let errorObject = {};
    if (error.includes('invalid-email')) {
        errorObject.email = 'Please provide a valid email';
    }
    if (error.includes('weak-password')) {
        errorObject.password = 'Password must be at least 6 characters long';
    }
    if (error.includes('passwords-do-not-match')) {
        errorObject.confirm_password = 'Passwords don\'t match';
    }
    if (error.includes('first-name-missing')) {
        errorObject.first_name = 'Please enter your first name';
    }
    if (error.includes('last-name-missing')) {
        errorObject.last_name = 'Please enter your last name';
    }

    if (Object.keys(errorObject).length > 0) {
        setError(errorObject);
    }
    else {
        if (error.includes('expired-action-code') || error.includes('invalid-action-code')) {
            setOtherError('Error: Link used to register has either expired or is invalid.');
        }
        else setOtherError(error);
    }
}

function handleRegister(email, password, name, setRegistering, setError, setOtherError, navigate) {
    let isError = false;
    if (email.length < 3) {
        handleError('invalid-email')
    }
    if (password[0] !== password[1]) {
        handleError('passwords-do-not-match', setError, setOtherError);
        return;
    }
    if (password[0].length < 6) {
        handleError('weak-password', setError, setOtherError);
        isError = true;
    }
    if (name[0].length === 0) {
        handleError("first-name-missing", setError, setOtherError);
        isError = true;
    }
    if (name[1].length === 0) {
        handleError("last-name-missing", setError, setOtherError);
        isError = true;
    }
    if (isError) return;
    setRegistering(true);
    createUserWithEmailAndPassword(fbAuth, email, password[0])
        .then((result) => {
            const userId = result.user.uid;
            dbApi.registerPresenter(userId, name[0], name[1])
                .then(() => {
                    const actionCodeSettings = {
                        url: process.env.REACT_APP_DOMAIN_URL + 'createSessions',
                        handleCodeInApp: true,
                    };
                    sendEmailVerification(result.user, actionCodeSettings)
                        .then(() => {
                            navigate('/confirm-email');
                        })
                    // navigate('/createSessions');
                    // dbApi.setPresenterIdToUserId(unverifiedPresenterId, userId, sessionId, result.user.email).then(() => {
                    //     dbApi.markSessionAsVerified(sessionId, presenter, email)
                    //         .then(() => {
                    //             navigate('/session/' + sessionId);
                    //             setRegistering(false);
                    //         })
                    //         .catch((error) => handleError(error.message, setError, setOtherError));
                    // })
                    //     .catch((error) => handleError(error.message, setError, setOtherError));
                })
                .catch((error) => handleError(error.message, setError, setOtherError));
        })
        .catch((error) => handleError(error.message, setError, setOtherError));
}

export function Register() {
    const [validated, setValidated] = useState(false);
    const [email, setEmail] = useState('');
    const [name, setName] = useState(['', '']);
    const [password, setPassword] = useState(['', '']);
    const [settingPassword, setSettingPassword] = useState(false);
    const [error, setError] = useState({});
    const [otherError, setOtherError] = useState("");
    const [changedFields, setChangedFields] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (Object.keys(error).length > 0 || otherError.length > 0) {
            setValidated(true);
            setSettingPassword(false);
        }
    }, [error, otherError]);

    return (<div className={"d-flex flex-column align-items-sm-center pt-3"} style={{height: "100%"}}>
            <FormCard title="Register" className={"ms-3 me-3 my-auto shadow user-form"} headerClassName={"register-form-header"}>
                <Form noValidate validated={validated}
                      onSubmit={(e) => {
                          e.preventDefault();
                          setError("");
                          setOtherError("");
                          setValidated(true);
                          setChangedFields({});
                          handleRegister(email, password, name, setSettingPassword, setError, setOtherError, navigate);
                      }}>
                    <Form.Group className={"mb-3"}>
                        <Form.Label>Email:</Form.Label>
                        <Form.Control className={"session-input-field"} type={"email"} value={email}
                                      placeholder={"john@doe.com"}
                                      onChange={(e) => {
                                          setEmail(e.target.value);
                                          setChangedFields({...changedFields, email: true});
                                      }}
                                      isValid={!!!error.email && validated}
                                      isInvalid={!!error.email && !changedFields.email}
                                      required/>
                        <Form.Control.Feedback
                            type={"invalid"}>{error.email ? error.email : 'Please provide your email'}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={"mb-3"}>
                        <Form.Label>First name:</Form.Label>
                        <Form.Control className={"session-input-field"} type={"text"} value={name[0]}
                                      placeholder={"John"}
                                      onChange={(e) => {
                                          setName([e.target.value, name[1]]);
                                          setChangedFields({...changedFields, name: true});
                                      }}
                                      isValid={!!!error.first_name && validated}
                                      isInvalid={!!error.first_name && !changedFields.first_name}
                                      required/>
                        <Form.Control.Feedback
                            type={"invalid"}>{error.first_name ? error.first_name : 'Please provide your first name'}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={"mb-3"}>
                        <Form.Label>Last name:</Form.Label>
                        <Form.Control className={"session-input-field"} type={"text"} value={name[1]}
                                      placeholder={"Doe"}
                                      onChange={(e) => {
                                          setName([name[0], e.target.value]);
                                          setChangedFields({...changedFields, last_name: true});
                                      }}
                                      isValid={!!!error.last_name && validated}
                                      isInvalid={!!error.last_name && !changedFields.last_name}
                                      required/>
                        <Form.Control.Feedback
                            type={"invalid"}>{error.last_name ? error.last_name : 'Please provide your last name'}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={"mb-3"}>
                        <Form.Label>Password:</Form.Label>
                        <Form.Control className={"session-input-field"} type={"password"} value={password[0]}
                                      placeholder={"******"}
                                      onChange={(e) => {
                                          setPassword([e.target.value, password[1]]);
                                          setChangedFields({...changedFields, password: true});
                                      }}
                                      isValid={!!!error.password && validated}
                                      isInvalid={!!error.password && !changedFields.password}
                                      required/>
                        <Form.Control.Feedback
                            type={"invalid"}>{error.password ? error.password : 'Please provide a password'}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className={"mb-3"}>
                        <Form.Label>Confirm password:</Form.Label>
                        <Form.Control className={"session-input-field"} type={"password"} value={password[1]}
                                      placeholder={"******"}
                                      onChange={(e) => {
                                          setPassword([password[0], e.target.value]);
                                          setChangedFields({...changedFields, confirm_password: true});
                                      }}
                                      isValid={!!!error.confirm_password && validated}
                                      isInvalid={!!error.confirm_password && !changedFields.confirm_password}

                                      required/>
                        <Form.Control.Feedback
                            type={"invalid"}>{error.confirm_password ? error.confirm_password : 'Please provide a password'}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <FormSubmitButton variant="primary" type="submit" disabled={settingPassword} className={"button-standard register-form-submit-button"}>
                        {settingPassword && <Spinner as={"span"} animation={"border"} size={"sm"} className={"me-1"}/>}
                        {settingPassword ? "Creating Account..." : "Register"}
                    </FormSubmitButton>
                    {otherError.length > 0 && <Alert className={"mt-3"} variant={"danger"}>{otherError}</Alert>}
                </Form>
            </FormCard>
        </div>)
}