import 'bootstrap/dist/css/bootstrap.min.css';
import './Footer.css'
import {SocialButton} from "./SocialButton";
import {Container} from "react-bootstrap";

export function Footer() {
    return (
        <Container>
            <footer id={"footer"} className={"py-3 my-4 border-top"}>
                <small id={"footer-text"} className={"text-muted"}>Regional Scrum Gathering Ghent 2024, by Re-Evented</small>
                <img id={"footer-logo"} src={"/rsg-ghent-favicon.png"} alt={"logo"}/>
                <span id={"footer-socialss"}>
                    <SocialButton url={"https://www.linkedin.com/company/re-evented"} logo={"/linkedin.svg"}/>
                </span>
                <a className={"text-muted"} style={{gridRow:2,justifyContent:'center',alignItems:'center',gridColumn:'1/4',placeSelf:'center',marginTop:"0.5%"}} href={'mailto:info@regionalscrumgathering-ghent2024.org'}>info@regionalscrumgathering-ghent2024.org</a>
            </footer>
        </Container>)
}