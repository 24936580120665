import {Button, Spinner} from "react-bootstrap";
import {useEffect, useMemo, useState} from "react";
import {dbApi} from "../../services/firebase";
import {Times} from "./Times";
import {Locations} from "./Locations";
import {SessionsMatrix} from "./SessionsMatrix";
import {cell} from "./Cell";
import Form from 'react-bootstrap/Form';
import {useNavigate} from "react-router-dom";
import {BsFillPencilFill} from "react-icons/bs";
import '../../styles/Program.css'
import {FormSubmitButton} from "../FormSubmitButton";
import {useWindowSize} from "../../hooks/useWindowSize";

// set coordinate in a state for each cell and pass that to the content so it can easily be accessed and edited ._.

function addNewTime(times, setTimes, sessionsMatrix, setSessionsMatrix) {
    setTimes([...times, {
        time: '00:00',
        y: times.length
    }]);
    const modifiedSessionsMatrix = sessionsMatrix.map((column) => {
        column.push(undefined);
        return column;
    })
    setSessionsMatrix(modifiedSessionsMatrix);
}

function addNewLocation(locations, setLocations, times, sessionsMatrix, setSessionsMatrix) {
    setLocations([...locations, {
        title: 'New location',
        x: locations.length
    }]);
    sessionsMatrix.push(Array.from({length: times.length}, () => undefined));
    setSessionsMatrix(sessionsMatrix);
}


export function Program(props) {
    const {id, isEdit, isExport, isNew} = props;
    const programPromise = useMemo(() => {
        if (id) return dbApi.getProgram(id)
    }, [id]);
    const locationsPromise = useMemo(() => dbApi.getLocationsOfProgram(id), [id]);
    const timesPromise = useMemo(() => dbApi.getTimesOfProgram(id), [id]);
    const cellsPromise = useMemo(() => dbApi.getCellsOfProgram(id), [id]);
    const allSessionsPromise = useMemo(() => dbApi.getAllSessions(), []);
    const [program, setProgram] = useState(isNew ? {title: ''} : undefined);
    const [locations, setLocations] = useState(undefined);
    const [times, setTimes] = useState(undefined);
    const [cells, setCells] = useState(undefined);
    const [matrix, setMatrix] = useState(undefined);
    const [loadedMatrix, setLoadedMatrix] = useState(false);
    const [filteredSessions, setFilteredSessions] = useState(undefined);
    const [sessions, setSessions] = useState(undefined);
    const [validated, setValidated] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    // Session cells change size once their presenters are loaded, so we want to rerender with new page height for the iframe
    const [sizeUpdated, setSizeUpdated] = useState(0);
    const navigate = useNavigate();
    const windowSize = useWindowSize();

    // Two useeffects to handle height changes for the iframe export
    useEffect(() => {
        if (isExport) window.parent.postMessage(windowSize.documentHeight, '*');
    }, [isExport, windowSize]);
    useEffect(() => {
        if (isExport && loadedMatrix) {
            window.parent.postMessage(window.document.body.scrollHeight, '*');
        }
    }, [isExport, loadedMatrix, sizeUpdated])

    useEffect(() => {
        if(!programPromise) return;
        programPromise.then((promisedProgram) => setProgram(promisedProgram));
    }, [programPromise]);
    useEffect(() => {
        locationsPromise.then((promisedLocations) => setLocations(promisedLocations.sort((a, b) => a.x - b.x)));
    }, [locationsPromise]);
    useEffect(() => {
        timesPromise.then((promisedTimes) => setTimes(promisedTimes.sort((a, b) => a.y - b.y)));
    }, [timesPromise]);
    useEffect(() => {
        allSessionsPromise.then((promisedSessions) => {
            setSessions(promisedSessions);
            setFilteredSessions(promisedSessions);
        });
    }, [allSessionsPromise]);
    useEffect(() => {
        cellsPromise.then((promisedCells) => {
            setCells(promisedCells);
        });
    }, [cellsPromise]);
    useEffect(() => {
        if (locations && times && !matrix) {
            const newMatrix = Array.from({length: locations.length}, () => Array.from({length: times.length}), () => undefined);
            setMatrix(newMatrix);
        }
    }, [locations, times])

    useEffect(() => {
        if (cells && locations && times && sessions && filteredSessions && !loadedMatrix && matrix) {
            const processedCellsPromise = cells.map(async (promisedCell) => {
                if (promisedCell.text) {
                    matrix[promisedCell.coordinates.x][promisedCell.coordinates.y] =
                        cell(
                            promisedCell.text,
                            {x: promisedCell.coordinates.x, y: promisedCell.coordinates.y, span: promisedCell.coordinates.span},
                            'text'
                        );
                }
                if (promisedCell.sessionRef) {
                    return dbApi.getSession(promisedCell.sessionRef.id)
                        .then((session) => {
                            matrix[promisedCell.coordinates.x][promisedCell.coordinates.y] =
                                cell(
                                    session,
                                    {x: promisedCell.coordinates.x, y: promisedCell.coordinates.y},
                                    'session'
                                );
                        });
                }
            });
            Promise.all(processedCellsPromise).then(() => {
                setMatrix(matrix);
                setLoadedMatrix(true);
            });
        }
    }, [times, cells, locations, matrix, sessions, loadedMatrix, filteredSessions, isEdit]);

    return (
        <div>
            {
                program && isEdit &&
                <>
                    <Form.Control type={'text'} defaultValue={program.title}
                                  onChange={(e) => {
                                      setValidated(false);
                                      setProgram({...program, title: e.target.value})
                                  }}/>
                    {
                        validated && !program.title &&
                        <p className={'invalid-feedback'} style={{display: 'unset'}}>Please enter a title.</p>
                    }
                </>

            }
            {
                program && !isEdit && !isExport &&
                <h1>{program.title}</h1>
            }
            {
                matrix && filteredSessions &&
                <>
                    <div className={'program-table'}>
                        <div style={{gridColumn: 1, gridRow: 1}}></div>
                        <Times times={times} setTimes={setTimes} isEdit={isEdit}/>
                        <Locations locations={locations} setLocations={setLocations} isEdit={isEdit}/>
                        <SessionsMatrix matrix={matrix} setMatrix={setMatrix} sessions={sessions}
                                        filteredSessions={filteredSessions}
                                        setFilteredSessions={setFilteredSessions} isEdit={isEdit} isExport={isExport}
                                        sizeUpdated={sizeUpdated} setSizeUpdated={setSizeUpdated}/>
                        {
                            isEdit &&
                            <>
                                <div className={'new-time-entry'}
                                     style={{
                                         gridRow: times.length + 2,
                                         gridColumn: '1 / ' + (locations.length + 2),
                                         display: 'grid',
                                         placeItems: 'stretch'
                                     }}>
                                    <Button onClick={() => addNewTime(times, setTimes, matrix, setMatrix)}>
                                        +
                                    </Button>
                                    {
                                        times.length > 1 &&
                                        <Button onClick={() => {
                                            let modifiedTimes = times;
                                            let modifiedMatrix = matrix.slice();
                                            modifiedTimes.pop();
                                            modifiedMatrix = modifiedMatrix.map((column) => {
                                                column.pop();
                                                return column;
                                            });
                                            setTimes(modifiedTimes);
                                            setMatrix(modifiedMatrix);
                                        }}>
                                            -
                                        </Button>
                                    }
                                </div>

                                <div className={'new-location-entry'}
                                     style={{
                                         gridRow: '1 / ' + (times.length + 2),
                                         gridColumn: locations.length + 2,
                                         display: 'grid',
                                         placeItems: 'stretch'
                                     }}>
                                    <Button
                                        onClick={() => addNewLocation(locations, setLocations, times, matrix, setMatrix)}>
                                        +
                                    </Button>
                                    {
                                        locations.length > 1 &&
                                        <Button onClick={() => {
                                            let modifiedLocations = locations;
                                            const modifiedMatrix = matrix.map(column => column.slice());
                                            modifiedLocations.pop();
                                            modifiedMatrix.pop();
                                            setLocations(modifiedLocations);
                                            setMatrix(modifiedMatrix);
                                        }}>
                                            -
                                        </Button>
                                    }
                                </div>
                            </>
                        }
                    </div>
                    {
                        isEdit &&
                        <FormSubmitButton disabled={submitting} onClick={() => {
                            setValidated(true);
                            if (!program.title) return;
                            setSubmitting(true);
                            isNew ?
                                dbApi.createProgram(program.title, locations, times, matrix)
                                    .then((programId) => {
                                        navigate('/programs/view/' + programId);
                                        setSubmitting(false);
                                        setValidated(false);
                                    })
                                :
                                dbApi.saveProgram(program, locations, times, matrix)
                                    .then(() => {
                                        navigate('/programs/view/' + program.id);
                                        setSubmitting(false);
                                        setValidated(false);
                                    });
                        }
                        }>
                            {
                                !submitting &&
                                "Save"
                            }
                            {
                                submitting &&
                                <>
                                    Saving...
                                    <Spinner as={"span"} animation={"border"} size={"sm"} className={"me-1"}/>
                                </>
                            }
                        </FormSubmitButton>
                    }
                    {
                        !isEdit && !isExport &&
                        <>
                            <Button onClick={() => {
                                navigate('/programs/edit/' + program.id)
                            }}>
                                <BsFillPencilFill/>
                            </Button>
                            <Button onClick={() => navigate('/programs/export/' + program.id)}>
                                Export
                            </Button>
                        </>
                    }
                </>
            }
        </div>
    )
}


