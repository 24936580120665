import {Container, Navbar, Nav, NavDropdown} from "react-bootstrap";
import {LinkContainer} from 'react-router-bootstrap'
import './HeaderColors.css';
import {useContext, useMemo, useState} from "react";
import {useAuthContext} from "../contexts/authContext";
import {fbAuth} from "../services/firebase";
import {HeaderActivePageContext} from "../contexts/headerActivePageContext";
import {Link} from "react-router-dom";
import {useWindowSize} from "../hooks/useWindowSize";

export function Header() {
    const [activeLink, setActiveLink] = useState("");
    const [expanded, setExpanded] = useState(false);
    const activePage = useContext(HeaderActivePageContext);
    const {user, currentPresenter} = useAuthContext();
    const windowSize = useWindowSize();
    const showAdminDropdown = useMemo((() => windowSize.width >= 992 && windowSize.width < 1400), [windowSize]);

    function handleNavigation(navigatedTo) {
        setActiveLink(navigatedTo);
        setExpanded(false);
    }

    return (
        <HeaderActivePageContext.Provider value={activeLink}>
            <Navbar bg={"light"} expand={"lg"} className="navMenu" expanded={expanded}>
                <Container fluid>
                    <LinkContainer to={'/dashboard'}
                                   onClick={() => {
                                       setActiveLink("home");
                                       setExpanded(false);
                                   }}>
                        <Navbar.Brand> <img src={`/logo.png`} alt={"logo RSG Ghent 2024"}/></Navbar.Brand>
                    </LinkContainer>
                    <Navbar.Toggle onClick={() => setExpanded(!expanded)}/>
                    <Navbar.Collapse>
                        <Nav className={"me-auto"}>
                            <LinkContainer to={'//www.regionalscrumgathering-ghent2024.org/'}
                                           active={activePage === "home"}
                                           onClick={() => {
                                               setActiveLink("home");
                                               setExpanded(false);
                                           }}>
                                <Nav.Link className={"navItem"}>Home</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to={'/dashboard'}
                                           active={activePage === "dashboard"}
                                           onClick={() => {
                                               setActiveLink("dashboard");
                                               setExpanded(false);
                                           }}>
                                <Nav.Link className={"navItem"}>Dashboard</Nav.Link>
                            </LinkContainer>
                            {
                                user && currentPresenter && (currentPresenter.submittedASession || currentPresenter.role === "admin") &&
                                <LinkContainer to={'/sessions'}
                                               active={activePage === "sessions"}
                                               onClick={() => {
                                                   setActiveLink("sessions");
                                                   setExpanded(false);
                                               }}>
                                    <Nav.Link className={"navItem"}>Sessions</Nav.Link>
                                </LinkContainer>
                            }
                            {
                                user && (user.emailVerified || (currentPresenter && currentPresenter.role === "admin")) &&
                                <LinkContainer to={'/createSessions'}
                                               active={activePage === "createsessions"}
                                               onClick={() => {
                                                   setActiveLink("createsessions");
                                                   setExpanded(false);
                                               }}>
                                    <Nav.Link className={"navItem"}>Create Session</Nav.Link>
                                </LinkContainer>
                            }
                            {user && currentPresenter && (currentPresenter.submittedASession || currentPresenter.role === "admin") &&
                                <LinkContainer to={'/presenters'}
                                               active={activePage === "presenters"}
                                               onClick={() => {
                                                   setActiveLink("presenters");
                                                   setExpanded(false);
                                               }}>
                                    <Nav.Link className={"navItem"}>presenters</Nav.Link>
                                </LinkContainer>}
                            {
                                user && currentPresenter?.role === "admin" &&
                                <>
                                    {
                                        showAdminDropdown &&
                                        <NavDropdown className={'navItem'} title={'Admin'}
                                                     active={['presenters', 'votes', 'programs', 'configuration'].includes(activeLink)}>
                                            <NavDropdown.Item as={Link} to={'/presenters'}
                                                              onClick={() => handleNavigation('presenters')}>Presenters</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/votes'}
                                                              onClick={() => handleNavigation('votes')}>Votes</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/programs'}
                                                              onClick={() => handleNavigation('programs')}>Programs</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/configuration'}
                                                              onClick={() => handleNavigation('configuration')}>Configuration</NavDropdown.Item>
                                            <NavDropdown.Item as={Link} to={'/statistics'}
                                                              onClick={() => handleNavigation('statistics')}>Statistics</NavDropdown.Item>
                                        </NavDropdown>
                                    }
                                    {
                                        !showAdminDropdown &&
                                        <>
                                            <LinkContainer to={'/votes'}
                                                           active={activePage === "votes"}
                                                           onClick={() => {
                                                               setActiveLink("votes");
                                                               setExpanded(false);
                                                           }}>
                                                <Nav.Link className={"navItem"}>Votes</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to={'/programs'}
                                                           active={activePage === "programs"}
                                                           onClick={() => {
                                                               setActiveLink("programs");
                                                               setExpanded(false);
                                                           }}>
                                                <Nav.Link className={"navItem"}>Programs</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to={'/configuration'}
                                                           active={activePage === "configuration"}
                                                           onClick={() => {
                                                               setActiveLink("configuration");
                                                               setExpanded(false);
                                                           }}>
                                                <Nav.Link className={"navItem"}>Configuration</Nav.Link>
                                            </LinkContainer>
                                            <LinkContainer to={'/statistics'}
                                                           active={activePage === "statistics"}
                                                           onClick={() => {
                                                               setActiveLink("statistics");
                                                               setExpanded(false);
                                                           }}>
                                                <Nav.Link className={"navItem"}>Statistics</Nav.Link>
                                            </LinkContainer>
                                        </>
                                    }
                                </>
                            }
                        </Nav>
                        <Nav>
                            {
                                !user &&
                                <>
                                    <LinkContainer to={'/register'}
                                                   active={activePage === "register"}
                                                   onClick={() => {
                                                       setActiveLink("register");
                                                       setExpanded(false);
                                                   }}>
                                        <Nav.Link className={"navItem"}>Register</Nav.Link>
                                    </LinkContainer>
                                    <LinkContainer to={'/login'}
                                                   active={activePage === "login"}
                                                   onClick={() => {
                                                       setActiveLink("login");
                                                       setExpanded(false);
                                                   }}>
                                        <Nav.Link className={"navItem"}>Login</Nav.Link>
                                    </LinkContainer>
                                </>

                            }
                            {
                                user &&
                                <LinkContainer to={'/profile/' + currentPresenter.id}>
                                    <Nav.Link className={"navItem"}>
                                        {currentPresenter.profilePicture &&
                                            <img height={"30em"} width={"30em"} style={{
                                                borderRadius: "50%", display: "inline-block",
                                                verticalAlign: "middle"
                                            }}
                                                 src={currentPresenter.profilePicture}
                                                 alt={"presenter"}/>}
                                        {"  " + currentPresenter.firstName}
                                        {/*{currentPresenter?.role === "admin" &&*/}
                                        {/*<> (Admin)</> }*/}
                                    </Nav.Link>
                                </LinkContainer>
                            }
                            {
                                user &&
                                <LinkContainer to={'/dashboard'}
                                               active={activePage === "logout"}
                                               onClick={() => {
                                                   fbAuth.signOut();
                                                   setExpanded(false);
                                               }}>
                                    <Nav.Link className={"navItem"}>Logout</Nav.Link>
                                </LinkContainer>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </HeaderActivePageContext.Provider>
    )
}