import {Link} from "react-router-dom";
import {useMemo, useState,useEffect} from "react";
import {dbApi} from "../services/firebase";

export function VotesRowFromSummaryList(props){
    const {session} = props;
    const [presenters,setPresenter]=useState()
    const presentersPromise = useMemo(() => dbApi.getPresentersByIds(getPresentersIds(session.presenters)), [session.presenters]);
    useEffect(() => {
        presentersPromise.then((promisedPresenters) => setPresenter(promisedPresenters));
    }, [presentersPromise]);
    return(
        <tr className={"sessionTableRow"}>
            <td style={session.topic!==undefined?topicStyle(session.topic):topicStyle("undef")}><Link to={'/session/'+session.id}> {session.title!==undefined?session.title:"undef"} </Link>
                {presenters&& <div style={{fontSize:"0.9em"}}>by {presenters?(presenters[0]?((presenters[1]&&(presenters[1].firstName!==''&&presenters[1].lastName!==''))?(<><Link to={"/profile/" + presenters[0].id} className={"sessionListLink"}>{presenters[0].firstName + " " + presenters[0].lastName}</Link><div className={"d-inline"}> & </div><Link to={"/profile/" + presenters[1].id} className={"sessionListLink"}>{presenters[1].firstName + " " + presenters[1].lastName}</Link></>):(<Link to={"/profile/" + presenters[0].id} className={"sessionListLink"}>{presenters[0].firstName + " " + presenters[0].lastName}</Link>)):("undefined")):("undefined")}</div>}
            </td>
            <td>{session.totalVotes}</td>
        </tr>
    )
}

function topicStyle(topic){
    if (topic === "Business/organizational agility"){
        return {backgroundColor: "#c7FFB1", color: "black"}
    }
    if (topic === "Team agility"){
        return {backgroundColor: "#FED28E", color: "black"}
    }
    if (topic === "Product agility"){
        return {backgroundColor: "#FEC9DC", color: "black"}
    }
    if (topic === "Technical agility"){
        return {backgroundColor: "#D6F9FF", color: "black"}
    }
    if (topic === "Leadership agility"){
        return {backgroundColor: "#FFFF99", color: "black"}
    }
    return {color: "black"}
}

function getPresentersIds(presenters){
    return presenters.filter(p=>p!==""&&p!==null&&p!==undefined)
}