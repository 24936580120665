import {DisplayCell, SessionCell, TextCell} from "./Cell";
import {CellSelector} from "./CellSelector";

export function SessionsMatrix(props) {
    const {matrix, setMatrix, sessions, filteredSessions, setFilteredSessions, isEdit, isExport, sizeUpdated, setSizeUpdated} = props;
    return (
        <>
            {
                matrix.map((row, columnIndex) => {
                    return row.map((matrixCell, rowIndex) => {
                        if (matrixCell) {
                            if (matrixCell.type === 'session') return (
                                <SessionCell session={matrixCell.object} coordinates={matrixCell.coordinates}
                                             matrix={matrix} setMatrix={setMatrix} isEdit={isEdit} isExport={isExport}
                                             key={columnIndex + ' ' + rowIndex} sizeUpdated={sizeUpdated} setSizeUpdated={setSizeUpdated}/>
                            )
                            if (matrixCell.type === 'text') return (
                                <TextCell text={matrixCell.object} coordinates={matrixCell.coordinates}
                                          matrix={matrix} setMatrix={setMatrix} isEdit={isEdit}
                                          key={columnIndex + ' ' + rowIndex}/>
                            )
                        } else if (isEdit) return (
                            <DisplayCell key={columnIndex + ' ' + rowIndex}
                                         coordinates={{x: columnIndex + 2, y: rowIndex + 2}}>
                                <CellSelector sessions={sessions} filteredSessions={filteredSessions}
                                              setFilteredSessions={setFilteredSessions} matrix={matrix}
                                              setMatrix={setMatrix} coordinates={{x: columnIndex, y: rowIndex}}
                                />
                            </DisplayCell>
                        )
                    });
                })
            }
        </>
    )
}