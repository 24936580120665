import {Button} from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import {Link} from "react-router-dom";
import {useMemo, useState} from "react";
import {dbApi} from "../../services/firebase";

export function DisplayCell(props) {
    const {children, coordinates} = props;
    return (
        <div className={'program-cell'} style={{
            gridRow: coordinates.y,
            gridColumn: coordinates.x
        }}>
            {children}
        </div>
    )
}

export function SessionCell(props) {
    const {session, coordinates, matrix, setMatrix, isEdit, isExport, sizeUpdated, setSizeUpdated} = props;
    const [presenters, setPresenters] = useState([]);
    useMemo(() => dbApi.getPresentersByIds(session.presenters)
        .then((promisedPresenters) => {
            setPresenters(promisedPresenters);
            if (!isEdit) setSizeUpdated(sizeUpdated + 1);
        }), [session]);

    if (isExport && session.state !== "Confirmed") return (
        <div className={'program-session-cell'}
             style={{
                 gridRow: coordinates.y + 2,
                 gridColumn: coordinates.x + 2,
             }}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div></div>
            </div>
        </div>
    );
    return (
        <div className={'program-session-cell'}
             style={{
                 gridRow: coordinates.y + 2,
                 gridColumn: coordinates.x + 2,
                 backgroundColor: topicStyle(session.topic)
             }}>
            {
                !isEdit &&
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Link className={'program-session-info'} to={'/public/session/view/' + session.id} target="_blank">{session.title}</Link>
                    <div className={'program-session-presenters'}>
                        {presenters[0] && presenters[0].firstName && presenters[0].lastName &&
                            <span className={'program-session-presenter'}>{presenters[0].firstName + ' ' + presenters[0].lastName}</span>
                        }
                        {/* Second presenter */}
                        {presenters[1] && presenters[1].firstName && presenters[1].lastName &&
                            <span className={'program-session-presenter'}> & {presenters[1].firstName + ' ' + presenters[1].lastName}</span>
                        }
                    </div>
                </div>
            }
            {
                isEdit &&
                <>
                    <div>{session.title}</div>
                    <Button variant={'danger'}
                            onClick={() => {
                                const modifiedMatrix = matrix.slice();
                                modifiedMatrix[coordinates.x][coordinates.y] = undefined;
                                setMatrix(modifiedMatrix);
                            }}>
                        Remove
                    </Button>
                </>
            }
        </div>
    )
}

export function TextCell(props) {
    const {text, coordinates, matrix, setMatrix, isEdit} = props;
    const style = {
        position: 'relative',
        zIndex: 3000,
        gridRow: coordinates.y + 2,
        gridColumn: coordinates.x + 2,
        backgroundColor: '#e1dbdb'
    }
    if (coordinates.span) style.gridColumn = '2 / ' + (matrix.length - coordinates.x + 2 + coordinates.x);
    return (
        <div className={'program-text-cell'}
             style={style}>
            {
                !isEdit &&
                <div className={'program-text'}><b>{text}</b></div>
            }
            {
                isEdit &&
                <>
                    <Form.Control
                        type={'text'}
                        onChange={(e) => {
                            const modifiedMatrix = matrix.slice();
                            modifiedMatrix[coordinates.x][coordinates.y] = cell(
                                e.target.value,
                                coordinates,
                                'text'
                            );
                            setMatrix(modifiedMatrix);
                        }}
                        defaultValue={text ? text : 'Text here'}>
                    </Form.Control>
                    <Form.Check type={'checkbox'} label={'Span entire row'} checked={!!coordinates.span} onChange={e => {
                        const modifiedMatrix = matrix.slice();
                        if (!!e.target.checked) {
                            modifiedMatrix.forEach((column, columnIndex) => {
                                column.forEach((row, rowIndex) => {
                                    if (rowIndex === coordinates.y) modifiedMatrix[columnIndex][rowIndex] = undefined;
                                })
                            });
                        }
                        modifiedMatrix[0][coordinates.y] = cell(
                            text,
                            {
                                ...coordinates,
                                x: 0,
                                span: !!e.target.checked
                            },
                            'text'
                        );
                        setMatrix(modifiedMatrix);
                    }}/>
                    <Button variant={'danger'}
                            onClick={() => {
                                const modifiedMatrix = matrix.slice();
                                modifiedMatrix[coordinates.x][coordinates.y] = undefined;
                                setMatrix(modifiedMatrix);
                            }}>
                        Remove
                    </Button>
                </>
            }
        </div>
    )
}

export function cell(object, coordinates, type) {
    return (
        {
            type: type,
            object: object,
            coordinates: coordinates,
        }
    )
}

function topicStyle(topic) {
    if (topic === "Business/organizational agility") return "#c7FFB1";
    if (topic === "Team agility") return "#FED28E";
    if (topic === "Product agility") return "#FEC9DC";
    if (topic === "Technical agility") return "#D6F9FF";
    if (topic === "Leadership agility") return "#FFFF99";
}