import '../styles/sessionsList.css'
import {Button, Card, Col, Form, Overlay} from "react-bootstrap";
import {useState, useEffect, useMemo, useRef} from "react";
import {dbApi} from "../services/firebase";
import {FormCard} from "./FormCard";
import {FormSubmitButton} from "./FormSubmitButton";
import {useAuthContext} from "../contexts/authContext";
import {useNavigate} from "react-router-dom";
import {DropdownWithFilter} from "./DropdownWithFilter";
import {FaInfo} from "react-icons/fa";
import "./CreateSession.css";

function handleSubmit(title, subtitle, shortDescription, presenters, setSubmitted, reloadAuthState, navigate) {
    setSubmitted(false);
    // This removes null values.
    // If second presenter is not set, it will be null, which we don't want to have in the array.
    presenters = presenters.filter(p => p);
    // Only pass id of presenters, we don't need more
    console.log(presenters);
    let presentersIds = presenters.map(p => p.id);

    dbApi.createSession(title, subtitle, shortDescription, presentersIds)
        .then((sessionId) => {
            dbApi.setPresenterHasSubmittedASession(presentersIds[0]);
            // So that sessions button in header shows up
            reloadAuthState();
            navigate('/session/' + sessionId);
        });
}


export default function CreateSession() {
    const stateSessionSubmissionPromise = useMemo(() => dbApi.getSessionSubmissionState(), []);
    const [stateSessionSubmission, setStateSessionSubmission] = useState(false)

    const [title, setTitle] = useState(undefined);
    const [subtitle, setSubtitle] = useState(undefined);
    const [shortDescription, setShortDescription] = useState(undefined);
    const [submitted, setSubmitted] = useState(false);
    const [secondPresenter, setSecondPresenter] = useState();
    const presentersPromise = useMemo(() => dbApi.getAllPresenters(), []);
    const [allPresenters, setAllPresenters] = useState([]);
    const [filteredPresenters, setFilteredPresenters] = useState([]);
    const {user, currentPresenter, reloadAuthState} = useAuthContext();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const target = useRef(null);

    useEffect(() => {
        stateSessionSubmissionPromise.then((promisedState) => {
            setStateSessionSubmission(promisedState);
        });
    }, [stateSessionSubmissionPromise]);

    useEffect(() => {
        presentersPromise?.then((promisedPresenters) => {
            if (!promisedPresenters) return;
            if (promisedPresenters.length === 0) return;
            if (!currentPresenter) return;
            setAllPresenters(promisedPresenters);
            let filtered = promisedPresenters.filter(p => currentPresenter?.id !== p.id);
            filtered = filtered.map((filteredPresenter) => {
                return {
                    text: filteredPresenter.firstName + " " + filteredPresenter.lastName,
                    object: filteredPresenter
                }
            });
            filtered.unshift({
                text: "No second presenter",
                object: null,
            });
            setFilteredPresenters(filtered);
        });
        // eslint-disable-next-line
    }, [presentersPromise, allPresenters, currentPresenter]);


    return (
        <>
            <Card className={"session-submission-info m-1 my-4"}>
                <Card.Body>
                    {!stateSessionSubmission ? <><h3>Session Submission is closed! Only maintainers can submit
                            sessions.</h3>
                            <h3>You can activate the session submission in the Configuration.</h3></> :
                        <h3>Session submission is done in 2 steps:</h3>
                    }
                    {!stateSessionSubmission && <p>Session submission is done in 2 steps.</p>}
                    <Col className={"ms-2"}>
                        <p>
                            -Step 1: Submit a high level proposal
                            <br/>
                            -Step 2: Iteratively and incrementally add details to your proposal as you get questions and
                            feedback
                        </p>
                    </Col>
                    <p>
                        Help other presenters develop their proposals, based on peer to peer feedback. You can give
                        feedback on other people's proposals and improve your proposal based on the feedback you
                        receive. In this way, we will come to a set of quality session proposals in a transparent,
                        community driven way.</p>
                </Card.Body>
            </Card>
            {user && (stateSessionSubmission || currentPresenter.role === 'admin') &&
                <FormCard title={"Create Session"} className={"m-1"} headerClassName={"create-session-form-card"}>
                    <Form className="create-session-form row g-3" onSubmit={(e) => {
                        e.preventDefault();
                        setSubmitted(true);
                        handleSubmit(title, subtitle, shortDescription, [currentPresenter, secondPresenter], setSubmitted, reloadAuthState, navigate);
                    }}>
                        <Form.Group className="col-12">
                            <Form.Label>Title:</Form.Label>
                            <Form.Control className={"session-input-field"} placeholder={"The title of your session"}
                                          type="text"
                                          onChange={(e) => setTitle(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="col-12">
                            <Form.Label>Subtitle:</Form.Label>
                            <Form.Control className={"session-input-field"}
                                          placeholder={"Sometimes your title is catchy but doesn't describe the content of your session. A subtitle can help. Keep it short!"}
                                          type="text" onChange={(e) => setSubtitle(e.target.value)} required/>
                        </Form.Group>
                        <Form.Group className="col-lg-3 col-md-6 col-sm-12">
                            <Form.Label>Second presenter:</Form.Label>
                            <DropdownWithFilter items={filteredPresenters}
                                                filterFn={(text) => {
                                                    let filtered = allPresenters.filter((filteredPresenter) => (filteredPresenter.firstName + " " + filteredPresenter.lastName).toUpperCase().includes((text).toUpperCase()));
                                                    filtered = filtered.filter(p => currentPresenter?.id !== p.id);
                                                    filtered = filtered.map((filteredPresenter) => {
                                                        return {
                                                            text: filteredPresenter.firstName + " " + filteredPresenter.lastName,
                                                            object: filteredPresenter
                                                        }
                                                    });
                                                    filtered.unshift({
                                                        text: "No second presenter",
                                                        object: null,
                                                    });
                                                    setFilteredPresenters(filtered);
                                                }}
                                                onClickFn={(presenter) => {
                                                    setSecondPresenter(presenter)
                                                }}
                                                title={secondPresenter ? secondPresenter.firstName + " " + secondPresenter.lastName : "Second presenter"}
                                                className={"button-standard create-session-second-presenter-button"}
                            />
                        </Form.Group>

                        <Form.Group className="input-group">
                            <Form.Label className="col-12">Short description:</Form.Label>
                            <Form.Control style={{height: "6em", borderRadius: "5px"}} className={"textAreaSessionEdit"}
                                          placeholder={"This will appear on the website, and it will also be displayed on the wall during the conference. This is a public description."}
                                          as="textarea" onChange={(e) => setShortDescription(e.target.value)} required/>
                        </Form.Group>
                        <div className="col-12">
                            {!submitted ? <FormSubmitButton className={"button-standard"}>Create session</FormSubmitButton> :
                                <FormSubmitButton className={"button-standard"} disabled>Create session</FormSubmitButton>}
                        </div>
                    </Form>
                </FormCard>}
        </>
    );
}
