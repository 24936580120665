import {Card, Container, Form, FormLabel, Row} from "react-bootstrap";
import {useMemo, useState} from "react";
import {useEffect} from "react";
import {FormSubmitButton} from "./FormSubmitButton";
import {Comments} from "./Comments";
import {dbApi} from "../services/firebase";
import {useAuthContext} from "../contexts/authContext";
import "./ReviewsColors.css";

export function Reviews(props) {
    const {sessionId} = props;
    const [reviews, setReviews] = useState([]);
    const [cardOpen, setCardOpen] = useState(true);
    const reviewsPromise = useMemo(() => dbApi.getReviewsOfSession(sessionId), [sessionId]);

    useEffect(() => {
        reviewsPromise.then((promisedReviews) => {
            if (promisedReviews.length === 0) return;
            const presenterIds = promisedReviews.map((promisedReview) => {
                return promisedReview.reviewedBy;
            });
            dbApi.getPresentersByIds(presenterIds).then((presenters) => {
                const completeReviews = promisedReviews.map((promisedReview) => {
                    presenters.forEach((presenter) => {
                        if (presenter.id === promisedReview.reviewedBy) promisedReview.reviewedBy = presenter;
                    });
                    return promisedReview;
                });
                setReviews(completeReviews);
            });
        });
    }, [reviewsPromise]);

    return (
        <Card className={"mt-3"}>
            <Card.Header className={"text-center card-header-standard session-reviews-header"}>
                <h1 className={"d-inline"}>Reviews for this session</h1>
                <button onClick={() => setCardOpen(!cardOpen)} style={{marginLeft: "1%", marginBottom: "0.7em"}}
                        className="btn d-inline session-reviews-header-button">{cardOpen ? "Close" : "Open"}</button>
            </Card.Header>
            <Card.Body style={cardOpen ? {display: "block", paddingTop: 0, paddingBottom: 0} : {display: "none"}}>
                {
                    reviews.sort(sortReviews).map(review => <Review key={review.id} review={review}/>)
                }
                <WriteReview sessionId={sessionId}/>
            </Card.Body>
        </Card>
    )
}

function sortReviews(a, b) {
    if (a.dateModified !== undefined && a.dateModified !== null) {
        if (b.dateModified !== undefined && b.dateModified !== null) {
            if (a.dateModified < b.dateModified) {
                return 1;
            }
            if (a.dateModified > b.dateModified) {
                return -1;
            }
            return 0;
        }
        if (b.dateCreated === undefined || b.dateCreated === null) {
            return -1
        }
        if (a.dateModified < b.dateCreated) {
            return 1;
        }
        if (a.dateModified > b.dateCreated) {
            return -1;
        }
        return 0;
    }
    if (b.dateModified !== undefined && b.dateModified !== null) {
        if (a.dateCreated === undefined || a.dateCreated === null) {
            return 1
        }
        if (a.dateCreated < b.dateModified) {
            return 1;
        }
        if (a.dateCreated > b.dateModified) {
            return -1;
        }
        return 0;
    }


    if (a.dateCreated === undefined || a.dateCreated === null) {
        return 1
    }
    if (b.dateCreated === undefined || b.dateCreated === null) {
        return -1
    }
    if (a.dateCreated < b.dateCreated) {
        return 1;
    }
    if (a.dateCreated > b.dateCreated) {
        return -1;
    }
    return 0;
}

function Review(props) {
    const {currentPresenter} = useAuthContext();
    const {review} = props;
    const [reviewOpen, setReviewOpen] = useState(true)
    const [editMode, setEditMode] = useState(false)
    //edit form useStates
    const [whatILike, setWhatILike] = useState(review.whatILike);
    const [improvementOpportunities, setImprovementOpportunities] = useState(review.improvementOpportunities);
    const [sessionScore, setSessionScore] = useState(review.sessionScore);

    return (!editMode ?
            <Container className={"session-review-container"} style={{
                borderStyle: "solid",
                borderWidth: "3px",
                borderRadius: "10px",
                padding: "0.8%",
                paddingTop: "0.5%",
                marginTop: "2em"
            }}>
                <h2 className={"d-inline"} style={{marginTop: 0}}>Reviewed
                    by {review.reviewedBy?.firstName + ' ' + review.reviewedBy?.lastName}</h2>
                <button style={{marginLeft: "1%", marginBottom: "0.7em"}} className="btn d-inline button-standard review-open-close-button"
                        onClick={() => setReviewOpen(!reviewOpen)}>{reviewOpen ? "Close" : "Open"}</button>
                <div style={reviewOpen ? {display: "block"} : {display: "none"}}>
                    <div style={{fontSize: "1.2em"}}>What I like:</div>
                    <div className={"session-review-like-container"} style={{
                        padding: "0.5%",
                        borderRadius: "5px"
                    }}>
                        {!review.whatILike.includes("%;;;%") ? review.whatILike : review.whatILike.split("%;;;%").map((l, index) =>
                            <div key={l + index}>{l}</div>)}
                    </div>
                    {review.improvementOpportunities === "" || review.improvementOpportunities === undefined || review.improvementOpportunities === null ? <>
                        <div style={{fontSize: "1.2em"}}>Nothing to improve!</div>
                    </> : <>
                        <div style={{fontSize: "1.2em"}}>I will give you a 10/10 if you improve these
                            things:</div>
                        <div className={"session-review-improve-container"} style={{
                            padding: "0.5%",
                            borderRadius: "5px"
                        }}>
                            {!review.improvementOpportunities.includes("%;;;%") ? review.improvementOpportunities : review.improvementOpportunities.split("%;;;%").map((i, index) =>
                                <div key={i + index}>{i}</div>)}
                        </div>
                    </>}
                    <Row>
                        <div style={{fontSize: "1.1em", marginBottom: 0}} className={"col-md-6 col-sm-12"}>This session
                            gets a <b>{review.sessionScore} / 10</b></div>
                        <div style={{marginBottom: 0, marginTop: "0.3em"}}
                             className={"session-review-created-text col-md-6 col-sm-12 text-end"}>Review
                            Created: {review.dateCreated ? <>{(new Date(review.dateCreated.seconds * 1000)).getDate()}/{(new Date(review.dateCreated.seconds * 1000)).getMonth() + 1}/{(new Date(review.dateCreated.seconds * 1000)).getFullYear()} {(new Date(review.dateCreated.seconds * 1000)).getHours() < 10 ? "0" + (new Date(review.dateCreated.seconds * 1000)).getHours() : (new Date(review.dateCreated.seconds * 1000)).getHours()}:{(new Date(review.dateCreated.seconds * 1000)).getMinutes() < 10 ? "0" + (new Date(review.dateCreated.seconds * 1000)).getMinutes() : (new Date(review.dateCreated.seconds * 1000)).getMinutes()}</> : "not defined"}</div>
                        <div style={{marginBottom: 0, marginTop: "0.3em", display: "inline"}}
                             className={"session-review-modified-text col-md-12 col-sm-12 text-end"}>{review.dateModified ? <>Review
                            Modified: {(new Date(review.dateModified.seconds * 1000)).getDate()}/{(new Date(review.dateModified.seconds * 1000)).getMonth() + 1}/{(new Date(review.dateModified.seconds * 1000)).getFullYear()} {(new Date(review.dateModified.seconds * 1000)).getHours() < 10 ? "0" + (new Date(review.dateModified.seconds * 1000)).getHours() : (new Date(review.dateModified.seconds * 1000)).getHours()}:{(new Date(review.dateModified.seconds * 1000)).getMinutes() < 10 ? "0" + (new Date(review.dateModified.seconds * 1000)).getMinutes() : (new Date(review.dateModified.seconds * 1000)).getMinutes()}</> : ''}</div>
                    </Row>
                    {currentPresenter?.id === review.reviewedBy.id ?
                        <button style={{marginTop: "0.5em"}} onClick={() => setEditMode(true)}
                                className="btn btn-light d-inline col-12">Edit your review</button> : <></>}
                    <Comments reviewId={review.id}/>
                </div>
            </Container> :
            <Container style={{
                border: "solid 3px #393939",
                borderRadius: "10px",
                padding: "0.8%",
                paddingTop: "0.5%",
                marginTop: "2em",
                marginBottom: "2em"
            }}>
                <h2 style={{marginTop: 0}}>Edit your review:</h2>
                <Form className="row g-3" onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmitEdit(review.id, whatILike, improvementOpportunities, sessionScore);
                    setEditMode(false)
                }}>
                    <Form.Group className="col-12">
                        <Form.Label style={{fontSize: "1.2em"}}>What I Like:</Form.Label>
                        <Form.Control defaultValue={review.whatILike.replaceAll("%;;;%", "\n")}
                                      className={"textAreaReview"} as={"textarea"}
                                      onChange={(e) => setWhatILike(e.target.value)} required/>
                    </Form.Group>
                    <Form.Group className="col-12">
                        <Form.Label style={{fontSize: "1.2em"}}>I will give you a 10/10 if you improve these
                            things:</Form.Label>
                        <Form.Control
                            defaultValue={review.improvementOpportunities !== null ? review.improvementOpportunities.replaceAll("%;;;%", "\n") : null}
                            className={"textAreaReview"} as={"textarea"}
                            onChange={(e) => setImprovementOpportunities(e.target.value)}/>
                    </Form.Group>
                    <Form.Group>
                        <div style={{fontSize: "1.2em"}}>I give this session <Form.Control
                            defaultValue={review.sessionScore} className={"slider-standard session-review-edit-slider"} type={"number"}
                            min={0} max={10} onChange={(e) => setSessionScore(e.target.value)} required/> / 10
                        </div>
                    </Form.Group>
                    <FormSubmitButton style={{marginLeft: "2em", marginRight: "2em"}}>Save review</FormSubmitButton>
                </Form>
            </Container>
    )
}

function WriteReview(props) {
    const {currentPresenter} = useAuthContext();
    const {sessionId} = props;
    const [whatILike, setWhatILike] = useState(undefined);
    const [improvementOpportunities, setImprovementOpportunities] = useState(undefined);
    const [sessionScore, setSessionScore] = useState(0);
    const [error1010, setError1010] = useState(false);
    const [posted, setPosted] = useState(false)
    return (
        <Container style={{
            border: "solid 3px #393939",
            borderRadius: "10px",
            padding: "0.8%",
            paddingTop: "0.5%",
            marginTop: "2em",
            marginBottom: "2em"
        }}>
            <h2 style={{marginTop: 0}}>Make a new review:</h2>
            <Form className={'d-flex flex-column gap-3'} onSubmit={(e) => {
                setPosted(true);
                e.preventDefault();
                handleSubmit(currentPresenter.id, sessionId, whatILike, improvementOpportunities, sessionScore, setError1010, setPosted);
            }}>
                <Form.Group>
                    <Form.Label style={{fontSize: "1.2em"}}>What I Like:</Form.Label>
                    <Form.Control className={"session-review-create-form-like-container"} as={"textarea"}
                                  style={{padding:"0.5%", borderRadius:"5px", height:"7em"}}
                                  onChange={(e) => setWhatILike(e.target.value)} required/>
                </Form.Group>
                <Form.Group>
                    <Form.Label style={{fontSize: "1.2em"}}>I will give you a 10/10 if you improve these
                        things:</Form.Label>
                    {error1010 ?
                        <div className={"error1010"}>There can't be things to improve if you give this session an
                            10/10!</div> : <></>}
                    <Form.Control className={"session-review-create-form-improve-container"} as={"textarea"}
                                  style={{padding:"0.5%", borderRadius:"5px", height:"7em"}}
                                  onChange={(e) => setImprovementOpportunities(e.target.value)}/>
                </Form.Group>
                <Form.Group>
                    <FormLabel> I give this session <strong>{sessionScore}</strong>/10</FormLabel>

                    <Form.Control style={{border: 0}} className={"shadow-none form-range slider-standard session-review-create-form-slider"} min={0} max={10} type="range" defaultValue={0}
                                  onChange={(e) => setSessionScore(e.target.value)}/>
                </Form.Group>
                {posted ? <FormSubmitButton className={"button-standard"} style={{marginLeft: "2em", marginRight: "2em"}} disabled>Post
                        review</FormSubmitButton> :
                    <FormSubmitButton className={"button-standard"} style={{marginLeft: "2em", marginRight: "2em"}}>Post review</FormSubmitButton>}
            </Form>
        </Container>
    )
}

function handleSubmit(reviewedBy, sessionId, whatILike, improvementOpportunities, sessionScore, setError1010, setPosted) {
    console.log(reviewedBy)
    if (parseInt(sessionScore) === 10 && improvementOpportunities !== undefined && improvementOpportunities !== null && improvementOpportunities !== "") {
        setError1010(true);
        setPosted(false);
        console.error("No improvement or opportunities possible when giving a perfect score.");
    } else dbApi.postReview(reviewedBy, sessionId, whatILike.replaceAll("\n", "%;;;%"), improvementOpportunities ? improvementOpportunities.replaceAll("\n", "%;;;%") : "", sessionScore)
        .then(() => window.location.reload());

}

function handleSubmitEdit(reviewId, whatILike, improvementOpportunities, sessionScore) {
    dbApi.editReview(reviewId, whatILike.replaceAll("\n", "%;;;%"), improvementOpportunities ? improvementOpportunities.replaceAll("\n", "%;;;%") : "", sessionScore).then(() => window.location.reload());
}