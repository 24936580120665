import React, {useState} from 'react'
import {Button, Modal} from "react-bootstrap";
import "./CookiePopup.css";

export function CookiePopup({dispatch}) {
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    return (
        <Modal show={show}>
            <Modal.Header>
                <Modal.Title><img src={`/logo.png`} alt={"logo regional scrum gathering"}/></Modal.Title>
            </Modal.Header>
            <Modal.Body>
               <h2>Terms and conditions</h2><br/>
                <p>Thank you for visiting our website,<br/>
                    <a href={"https://regionalscrumgathering-ghent2024.org/code-of-conduct/"}>code of conduct</a>
                    <strong> please read carefully before you use the website. </strong><br/>
                    Access to and use of this site are subject to the following terms and conditions.
                    Please review these terms and conditions on a periodic basis, as they are subject to modification,
                    alteration, and/or update at any time at the sole discretion of Regional Scrum Gathering Ghent 2024.
                    If you do not agree to any terms or conditions, you should not use this site.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button className={"button-standard cookie-popup-accept-button"} onClick={() => dispatch({type: 'acceptCurrent'},{handleClose})}>Accept</Button>
            </Modal.Footer>
        </Modal>

    );
}